import Keycloak from "keycloak-js";

export const keycloak = new Keycloak("/keycloak.json");

const initKeycloak = (onAuthenticatedCallback) => {
  keycloak
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      if (!authenticated) {
        onAuthenticatedCallback();
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const getToken = () => keycloak.token;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback) =>
  keycloak.updateToken(5).then(successCallback).catch(doLogin);

export const AuthService = {
  doLogin,
  doLogout,
  isLoggedIn,
  initKeycloak,
  getToken,
  updateToken,
};
