import { lazy } from "react";
import { Navigate } from "react-router-dom";
import ModuleSuspense from "./shared/ModuleSuspense";
import LayoutSuspense from "./shared/LayoutSuspense";

const Agreement = lazy(() => import("./pages/policies/agreement"))

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
const AuthLayout = lazy(() => import("./layouts/Auth"));
const DashboardLayout = lazy(() => import("./layouts/Dashboard"));

// Auth components
const SignIn = lazy(() => import("./pages/auth"));

// Dashboard components
const Dashboard = lazy(() => import("./pages/modules/dashboard"));
const Apps = lazy(() => import("./pages/modules/applications"));

const PersonalInfo = lazy(() => import("./pages/modules/personal-info"));

export const authenticated_routes = [
  {
    path: "/",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/dashboard",
        element: (
          <ModuleSuspense>
            <Dashboard />
          </ModuleSuspense>
        ),
      },
      { path: "/", element: <Navigate to="/dashboard" /> },
      {
        path: "*",
        element: <Navigate to="/dashboard" />,
      },
    ],
  },
  {
    path: "/personal-info",
    element: (
      <ModuleSuspense>
        <DashboardLayout />
      </ModuleSuspense>
    ),
    children: [
      {
        path: "/personal-info",
        element: (
          <ModuleSuspense>
          <PersonalInfo/>
        </ModuleSuspense>
        )
      }
    ]
  },
  {
    path: "/apps",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/apps",
        element: (
          <ModuleSuspense>
            <Apps />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/agreement",
    element: (
      <LayoutSuspense>
        <AuthLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/agreement",
        element: (
          <ModuleSuspense>
            <Agreement />
          </ModuleSuspense>
        ),
      },
    ],
  }
];

export const unauthenticated_routes = [
  {
    path: "/",
    element: (
      <LayoutSuspense>
        <AuthLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/sign-in",
        element: (
          <ModuleSuspense>
            <SignIn />
          </ModuleSuspense>
        ),
      },
      {
        path: "/",
        element: <Navigate to="/sign-in" />,
      },
      {
        path: "*",
        element: <Navigate to="/sign-in" />,
      },
    ],
  },
];
