import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user";
import userApplicationsReducer from "./slices/user_applications";

export const store = configureStore({
  reducer: {
    user: userReducer,
    apps: userApplicationsReducer
  },
});
