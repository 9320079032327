import { createSlice } from "@reduxjs/toolkit";
const userApplicationsSlice = createSlice({
  name: "userApplications",
  initialState: {
    apps: [],
  },
  reducers: {
    addUserApplicationsToStore: (state, action) => {
      state.apps = action.payload;
    },
  },
});

export const { addUserApplicationsToStore } = userApplicationsSlice.actions;
export default userApplicationsSlice.reducer;