import { CircularProgress, Grid } from "@mui/material";

export const GeneralLoader = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      data-testid="generic-loader"
    >
      <Grid item my={8}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export const DialogLoader = () => {
  return (
    <Grid
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 9,
      }}
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      data-testid="dialog-loader"
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};
